<div class="row" style="width: 100%;margin: auto;">
  <div class="col-xl-3 col-xs-3" style="padding-left: 0;">
    <app-menu></app-menu>
  </div>
  <div class="col-xl-9 col-xs-9" style="margin-top: 20px;">
    <div class="page-title" *ngIf="!isAdmin && !isSousAdmin">
      <app-credit-disponible></app-credit-disponible>
    </div>
    <div class="page-title">
      <h3 class="breadcrumb-header">Suivi campagnes</h3>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-body">
              <div class="table-responsive">
                <table class="display table dataTable">
                  <tr>
                    <td colspan="7">
                      Début
                    </td>
                    <td colspan="6">
                      Fin
                    </td>
                    <td colspan="3"></td>
                  </tr>
                  <tr>
                    <td colspan="7">
                      <input type="date" class="form-control" [(ngModel)]="search.debut" />
                    </td>
                    <td colspan="6">
                      <input type="date" class="form-control" [(ngModel)]="search.fin" />
                    </td>
                    <td colspan="3">
                      <button class="btn btn-success" (click)="getCampagnes()">Rechercher</button>
                    </td>
                  </tr>
                  <tr>
                    <td *ngIf="isAdmin || isSousAdmin"></td>
                    <td *ngIf="isAdmin || isSousAdmin">
                      Priorité
                    </td>
                    <td *ngIf="isAdmin || isSousAdmin">
                      Clients
                    </td>
                    <td style="width: 150px;">
                      Ref. campagnes
                    </td>
                    <td style="width: 150px;">
                      TOTAL
                    </td>
                    <td style="width: 150px;">
                      DOUBLONS
                    </td>
                    <td style="width: 150px;">
                      Too long
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                      Ancien blacklist
                    </td>
                    <td style="width: 150px;">
                      D&Eacute;LIVR&Eacute;S
                    </td>
                    <td style="width: 150px;">
                      PILE D'ENVOIES
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                      FAILED
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                      Nouveau Blacklist
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                      REPONSES
                    </td>
                    <td style="width: 150px;">
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      TOTAUX
                    </td>
                    <td>
                      #
                    </td>
                    <td style="width: 150px;">
                      #
                    </td>
                    <td style="width: 150px;">
                      {{totalEnvoi.total}}
                    </td>
                    <td style="width: 150px;">
                      #
                    </td>
                    <td style="width: 150px;">
                      #
                    </td>
                    <td style="width: 150px;">
                      #
                    </td>
                    <td style="width: 150px;">
                      {{totalEnvoi.envoyer}}
                    </td>
                    <td style="width: 150px;">
                      {{totalEnvoi.enAttente}}
                    </td>
                    <td style="width: 150px;">
                      {{totalEnvoi.failed}}
                    </td>
                    <td style="width: 150px;">
                      #
                    </td>
                    <td style="width: 150px;">
                      #
                    </td>
                    <td style="width: 150px;">
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                    </td>
                  </tr>
                  <tr *ngFor="let s of smpp">
                    <td style="text-align: right;" *ngIf="isAdmin || isSousAdmin">
                    </td>
                    <td style="text-align: right;" *ngIf="isAdmin || isSousAdmin">
                      <img src="assets/img/lancer.png" *ngIf="s.isOn == 'off'" (click)="updateIsOn('users', s._id, 'on')" style="width: 25px;" />
                      <img src="assets/img/arreter.png" *ngIf="s.isOn == 'on'" (click)="updateIsOn('users', s._id, 'off')" style="width: 25px;" />
                    </td>
                    <td *ngIf="isAdmin || isSousAdmin" [ngStyle]="{'background-color': s.assezCredit ? '': 'red'}">
                      SMPP ({{s.identifiant}})
                    </td>
                    <td style="width: 150px;">
                      NA
                    </td>
                    <td style="width: 150px;">
                      <img (click)="exporterTotal('user', s._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{s.total}}
                    </td>
                    <td style="width: 150px;">
                      <img (click)="exporterDuplicate('user', s._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{s.nombreDuplicate}}
                    </td>
                    <td style="width: 150px;">
                      <img (click)="exporterTooLong('user', s._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{s.nombreTooLong}}
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                      0
                    </td>
                    <td style="width: 150px;">
                      {{s.envoyer}}
                    </td>
                    <td style="width: 150px;">
                      <img (click)="exporterPile('user', s._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{s.enAttente}}
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                      <img (click)="exporterFailed('user', s._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{s.failed}}
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                      <img (click)="exporterStop('user', s._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{s.nombreStop}}
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                      <img (click)="exporterReceptions('user', s._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{s.nombreReception}}
                    </td>
                    <td style="text-align: left;">
                      <div class="menu">
                        <button class="form-control btn btn-danger" (click)="supprimerSMPP(s._id, 0)" style="height: auto;">Supprimer en attente</button>
                      </div>
                    </td>
                    <td colspan="2" style="text-align: left;">
                      <div class="menu">
                        <button class="form-control btn btn-danger" (click)="supprimerSMPP(s._id, 1)" style="height: auto;">Supprimer total</button>
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let c of campagnes">
                    <td style="text-align: right;" *ngIf="isAdmin || isSousAdmin">
                      <input type="number" class="form-control" [(ngModel)]="c.priorite" style="width: 100px;" (keydown.enter)="updatePriorite(c._id)" />
                    </td>
                    <td style="text-align: right;" *ngIf="isAdmin || isSousAdmin">
                      <img src="assets/img/lancer.png" *ngIf="c.isOn == 'off'" (click)="updateIsOn('campagnes', c._id, 'on')" style="width: 25px;" />
                      <img src="assets/img/arreter.png" *ngIf="c.isOn == 'on'" (click)="updateIsOn('campagnes', c._id, 'off')" style="width: 25px;" />
                    </td>
                    <ng-container *ngFor="let cc of clients">
                      <ng-container *ngIf="cc._id == c.idClient">
                        <td *ngIf="isAdmin || isSousAdmin" [ngStyle]="{'background-color': cc.assezCredit ? '': 'red'}">
                          <span>{{cc.identifiant}}</span>
                        </td>
                      </ng-container>
                    </ng-container>
                    <td style="width: 150px;">
                      <div class="reference">
                        <img src="assets/img/loupe.png" style="width: 25px;" /> <br />{{c.nomCampagne}} ({{ c.schedule * 1000 | date: 'dd/MM/yyyy HH:mm' }})
                        <div>
                          <textarea rows="6" disabled>{{c.message}}</textarea>
                        </div>
                      </div>
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                      <img (click)="exporterTotal('campagne', c._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{c.total + c.nbAncienBlacklist}}
                    </td>
                    <td style="width: 150px;">
                      <img (click)="exporterDuplicate('campagne', c._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{c.nombreDuplicate}}
                    </td>
                    <td style="width: 150px;">
                      <img (click)="exporterTooLong('campagne', c._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{c.nombreTooLong}}
                    </td>
                    <td style="width: 150px;">
                      {{c.nbAncienBlacklist}}
                    </td>
                    <td style="width: 150px;">
                      <img (click)="exporterDelivered('campagne', c._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{c.envoyer}}
                    </td>
                    <td style="width: 150px;">
                      <img (click)="exporterPile('campagne', c._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{c.enAttente}}
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                      <img (click)="exporterFailed('campagne', c._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{c.failed}}
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                      <img (click)="exporterStop('campagne', c._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{c.nombreStop}}
                    </td>
                    <td style="width: 150px;" *ngIf="isAdmin || isSousAdmin">
                      <img (click)="exporterReceptions('campagne', c._id)" src="assets/img/administration/download.png" style="width: 15px;"> {{c.nombreReception}}
                    </td>
                    <td style="text-align: left;">
                      <div class="menu">
                        <button class="btn btn-success" routerLink="/modifier-campagne/{{c._id}}" [disabled]="c.message == 'Message différent par destinataire'">Modifier</button>
                      </div>
                    </td>
                    <td style="text-align: left;">
                      <div class="menu">
                        <button class="btn btn-danger" (click)="mettreArchive(c._id)">Archive</button>
                      </div>
                    </td>
                    <td style="text-align: left;" *ngIf="isAdmin || isSousAdmin">
                      <div class="menu">
                        <button class="btn btn-danger" (click)="supprimer(c._id)">Supprimer</button>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
