import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../services/global.service";

@Component({
  selector: 'app-sim-management',
  templateUrl: './sim-management.component.html',
  styleUrls: ['./sim-management.component.css']
})
export class SimManagementComponent implements OnInit {
  ports: any = [];
  slots: any = [];
  detail: any = [];
  parametrages: any = [];
  init: any = [1, 2, 3, 4, 5, 6, 7, 8];
  files: Array<File> = [];
  colonne = [false, false, false, false, false, false, false, false];
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService) { }

  ngOnInit(): void {
    this.get();
    this.getParametrages();
  }

  multipleChoose(choose: string, value: number): void {
    if (choose == 'colonne') {
      this.colonne[value] = !this.colonne[value];
    }
    for (let ss of this.slots) {
      for (let s of ss) {
        let port = s.port.split('.');
        if (value+1 == parseInt(port[1])) {
          if (this.colonne[value]) {
            s.open = true;
          } else {
            s.open = false;
          }
        }
      }
    }
  }

  get(): void {
    this.slots = [];
    this.httpClient
      .get<any>(this.globalService.url + '/ports_rechargement', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.ports = response.ports;
          let newPort = [];
          let s: any = [];
          for (let p of this.ports) {
            p.open = false;
            let port = p.port.split('.');
            if (port[1] == "01") {
              newPort.push(parseInt(port[0]));
            }

            s.push(p);

            if (port[1] == "08") {
              s.numLigne = parseInt(port[0]);
              this.slots.push(s);
              s = [];
            }
          }
          this.ports = newPort;
        }
      }, () => {
      });
  }

  openClose(): void {
    if (confirm('Etes-vous sur ?')) {
      this.httpClient
        .get<any>(this.globalService.url + '/ports_rechargement/open-close', {headers: this.globalService.headers()})
        .subscribe((response) => {
          if (response.status) {
            alert('Demande transmise');
            this.get();
          }
        }, () => {
        });
    }
  }

  getParametrages(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/parametrages/ouverture_automatique', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.parametrages = response.parametres;
        }
      }, () => {
      });
  }

  updateParam(): void {
    this.httpClient
      .post<any>(this.globalService.url + '/parametrages', {parametres: this.parametrages}, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          alert('Modifié');
        }
      }, () => {
      });
  }

  reset(status: boolean): void {
    this.httpClient
      .post<any>(this.globalService.url + '/ports_rechargement/reset', {status: status, slots: this.slots},{headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.get();
        }
      }, () => {
      });
  }

  synchroniser(): void {
    this.httpClient
      .post<any>(this.globalService.url + '/ports_rechargement/synchroniser', {slots: this.slots},{headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.get();
        }
      }, () => {
      });
  }

  clic_checkbox(port: string) {
    let blocPort = null;
    for (let ss of this.slots) {
      for (let s of ss) {
        if (s.port == port) {
          if (!s.open) {
            s.open = true;
          } else {
            s.open = false;
          }
          blocPort = ss;
          break;
        }
      }
    }
    for (let b of blocPort) {
      if (b.port != port) {
        b.open = false;
      }
    }
  }
}
