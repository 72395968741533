import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../services/global.service";

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.css']
})
export class RechargeComponent implements OnInit {
  ports: any = [];
  slots: any = [];
  detail: any = [];
  init: any = [1, 2, 3, 4, 5, 6, 7, 8];
  amount = -1;
  upload = false;
  nbImporter = 0;
  files: Array<File> = [];
  quotaProchainCode?: number;
  ligne = [false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false];
  colonne = [false, false, false, false, false, false, false, false];
  all = false;
  actuel = Date.now();
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService) { }

  ngOnInit(): void {
    this.get();
  }
  nbJours(timestamp: number, quotaProchainCode: number): string {
    let dateExpiration = new Date(timestamp);

    dateExpiration.setDate(dateExpiration.getDate() + quotaProchainCode);

    const tempsRestantEnMillisecondes = dateExpiration.getTime() - this.actuel;

    if (tempsRestantEnMillisecondes < 0) {
      return "Fini";
    }

    const jours = Math.floor(tempsRestantEnMillisecondes / (1000 * 60 * 60 * 24));
    const heures = Math.floor((tempsRestantEnMillisecondes % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    let duree = "";

    if (jours > 0) {
      duree += jours + " jours ";
    }
    if (heures > 0) {
      duree += heures + " heures ";
    }

    return "Durée : "+duree;
  }
  nbActive(): any {
    let total = 0;
    for (let ss of this.slots) {
      for (let s of ss) {
        let dateExpiration = new Date(s.dernierRechargement * 1000);

        dateExpiration.setDate(dateExpiration.getDate() + s.dernierRechargementDuree);

        const tempsRestantEnMillisecondes = dateExpiration.getTime() - this.actuel;

        if (tempsRestantEnMillisecondes >= 0) {
          total++;
        }
      }
    }
    return total;
  }
  couleur(timestamp: number, duree: number): string {

    let dateExpiration = new Date(timestamp);

    dateExpiration.setDate(dateExpiration.getDate() + duree);

    console.log(dateExpiration);

    const tempsRestantEnMillisecondes = dateExpiration.getTime() - this.actuel;

    if (tempsRestantEnMillisecondes < 0) {
      return "red";
    }

    const jours = Math.floor(tempsRestantEnMillisecondes / (1000 * 60 * 60 * 24));

    if (jours == 0) {
      return "red";
    }

    if (jours == 1) {
      return "orange";
    }

    return "black";
}
  multipleChoose(choose: string, value: number): void {
    if (choose == 'all') {
      this.all = !this.all;
    } else if (choose == 'colonne') {
      this.colonne[value] = !this.colonne[value];
    } else {
      this.ligne[value] = !this.ligne[value];
    }
    if (this.amount != -1) {
      for (let ss of this.slots) {
        for (let s of ss) {
          let port = s.port.split('.');
          if (choose == 'all') {
            if (this.all) {
              s.asRecharger = this.amount;
            } else {
              s.asRecharger = '';
            }
          } else if (choose == 'colonne' && value+1 == parseInt(port[1])) {
            if (this.colonne[value]) {
              s.asRecharger = this.amount;
            } else {
              s.asRecharger = '';
            }
          } else if (choose == 'ligne' && value+1 == parseInt(port[0])) {
            if (this.ligne[value]) {
              s.asRecharger = this.amount;
            } else {
              s.asRecharger = '';
            }
          }
        }
      }
    }
  }
  get(): void {
    this.slots = [];
    this.httpClient
      .get<any>(this.globalService.url + '/ports_rechargement', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.ports = response.ports;
          let newPort = [];
          let s: any = [];
          for (let p of this.ports) {
            p.asRecharger = '';
            let port = p.port.split('.');
            if (port[1] == "01") {
              newPort.push(parseInt(port[0]));
            }

            s.push(p);

            if (port[1] == "08") {
              s.numLigne = parseInt(port[0]);
              this.slots.push(s);
              s = [];
            }
          }
          this.ports = newPort;
          this.getDetailRecharges();
        }
      }, () => {
      });
  }
  getDetailRecharges(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/recharges', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.detail = response.detail;
        }
      }, () => {
      });
  }
  fileChange(element: any) {
    this.files = element.target.files;
  }
  import(): void {
    this.upload = true;
    this.nbImporter = 0;
    let formData = new FormData();
    for (let i = 0; i < this.files.length; i++) {
      formData.append("uploads[]", this.files[i], this.files[i].name);
    }
    this.httpClient
      .post<any>(this.globalService.url + '/recharges', formData)
      .subscribe((response) => {
        if (response.status) {
          this.nbImporter += response.total;
          this.upload = false;
          this.getDetailRecharges();
        }
      }, () => {
        this.upload = false;
      });
  }
  export(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/recharges/export', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/recharges/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  clic_checkbox(port: string) {
    for (let ss of this.slots) {
      for (let s of ss) {
        if (s.port == port) {
          if (s.asRecharger == '') {
            s.asRecharger = this.amount;
          } else {
            s.asRecharger = '';
          }
          break;
        }
      }
    }
  }
  removeRecharge(port: string): void {
    for (let ss of this.slots) {
      for (let s of ss) {
        if (s.port == port) {
          this.httpClient
            .get<any>(this.globalService.url + '/ports_rechargement/removeRecharge/' + s._id, {headers: this.globalService.headers()})
            .subscribe((response) => {
              if (response.status) {
                this.get();
              } else {
                alert('Erreur lors de la modification');
              }
            }, () => {
            });
          break;
        }
      }
    }
  }
  recharger(): void {
    let montant = "";
    let operateur = "";
    let ids = [];

    for (let d of this.detail) {
      if (d.id == this.amount) {
        montant = d.montant;
        operateur = d.operateur;
      }
    }

    for (let ss of this.slots) {
      for (let s of ss) {
        if (s.asRecharger == this.amount && s.asRecharger != '' && s.asRecharger != -1 && s.codeEnAttente == '') {
          ids.push(s._id);
        }
      }
    }

    if (ids.length > 0 && this.quotaProchainCode != undefined && this.quotaProchainCode != null && this.quotaProchainCode >= 0) {
      this.httpClient
        .post<any>(this.globalService.url + '/ports_rechargement/recharge',
          {montant: montant, operateur: operateur, quotaProchainCode: this.quotaProchainCode, ids: ids}, {headers: this.globalService.headers()})
        .subscribe((response) => {
          if (response.status) {
            this.get();
          } else {
            alert('Erreur lors de la modification');
          }
        }, () => {
        });
    }
  }
}
