<div class="row" style="width: 100%;margin: auto;">
  <div class="col-xl-3 col-xs-3">
    <app-menu></app-menu>
  </div>
  <div class="col-xl-9 col-xs-9" style="margin-top: 20px;">
    <div class="page-title">
      <h3 class="breadcrumb-header">Rechargement</h3>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-body">
              <div class="table-responsive">
                <table class="display table dataTable">
                  <tr>
                    <td colspan="3">
                      <button (click)="reset(true)" class="form-control btn-warning">Notifier comme utilisé</button>
                    </td>
                    <td colspan="3">
                      <button (click)="reset(false)" class="form-control btn-success">Renouveller les sims</button>
                    </td>
                    <td colspan="2">
                      <button (click)="synchroniser()" class="form-control btn-success">Synchroniser les ports</button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8">
                      <table style="width: 100%;">
                        <tr>
                          <td>
                            Combien de puce à ouvrir en simultanée ?
                          </td>
                          <td>
                            A quelle minute de chaque heure lancer les commandes ?
                          </td>
                          <td>
                            A quelle minute de chaque heure ouvrir les ports ?
                          </td>
                          <td>
                            Quota par puce ?
                          </td>
                          <td>
                            Activer l'option automatique ?
                          </td>
                          <td>
                            Estimation fin
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <ng-container *ngFor="let p of parametrages">
                              <input *ngIf="p.number == 32" [(ngModel)]="p.value" class="form-control" type="number" placeholder="Nombre en simultannée..." />
                            </ng-container>
                          </td>
                          <td>
                            <ng-container *ngFor="let p of parametrages">
                              <input *ngIf="p.number == 33" [(ngModel)]="p.value" class="form-control" type="text" placeholder="Minute lancement..." />
                            </ng-container>
                          </td>
                          <td>
                            <ng-container *ngFor="let p of parametrages">
                              <input *ngIf="p.number == 36" [(ngModel)]="p.value" class="form-control" type="text" placeholder="Minute d'ouverture..." />
                            </ng-container>
                          </td>
                          <td>
                            <ng-container *ngFor="let p of parametrages">
                              <input *ngIf="p.number == 34" [(ngModel)]="p.value" class="form-control" type="number" placeholder="Quota par puce..." />
                            </ng-container>
                          </td>
                          <td>
                            <ng-container *ngFor="let p of parametrages">
                              <select class="form-control" *ngIf="p.number == 35" [(ngModel)]="p.value">
                                <option value="1" [selected]="p.value == '1'">Oui</option>
                                <option value="2" [selected]="p.value == '2'">Non</option>
                              </select>
                            </ng-container>
                          </td>
                          <td>
                            <ng-container *ngFor="let p of parametrages">
                              <input *ngIf="p.number == 40" [(ngModel)]="p.value" class="form-control" type="number" placeholder="Estimation fin..." />
                            </ng-container>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="8">
                      <button class="form-control btn-success" (click)="updateParam()">Valider ma sélection</button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8">
                      <button class="form-control btn-success" (click)="openClose()">Ouvrir manuellement</button>
                    </td>
                  </tr>
                  <tr>
                    <td *ngFor="let i of init">
                      <input type="checkbox" (click)="multipleChoose('colonne', i-1)" [(ngModel)]="colonne[i-1]" />
                    </td>
                  </tr>
                  <tr *ngFor="let ss of slots">
                    <td *ngFor="let s of ss" class="slot">

                      <div *ngIf="s.fermetureManuel" style="color: orange;">
                        <strong style="font-size: 18px;">{{s.port}}</strong>
                      </div>

                      <div *ngIf="s.problemeOuverture && !s.fermetureManuel" style="color: purple;">
                        <strong style="font-size: 18px;">{{s.port}}</strong>
                      </div>

                      <div *ngIf="s.used && !s.problemeOuverture && !s.fermetureManuel" style="color: red;">
                        <strong style="font-size: 18px;">{{s.port}}</strong>
                      </div>

                      <div *ngIf="s.actif && !s.used && s.state == 3 && !s.problemeOuverture && !s.realOpened" style="color: blue;">
                        <strong style="font-size: 18px;">{{s.port}} ({{s.signal}}/30)</strong>
                      </div>

                      <div *ngIf="s.actif && !s.used && s.state == 3 && !s.problemeOuverture && s.realOpened" style="color: darkgreen;">
                        <strong style="font-size: 18px;">{{s.port}} ({{s.signal}}/30)</strong>
                      </div>

                      <div *ngIf="!s.used && !s.problemeOuverture && !s.actif" style="color: black;">
                        {{s.port}}
                      </div>
                      <input type="checkbox" (click)="clic_checkbox(s.port)" [checked]="s.open != ''" />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
