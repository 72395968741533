import { Injectable } from '@angular/core';
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class GlobalService {

  accounts = [];
  url = 'https://smsmarket.xyz:8443';
  /*url = 'https://api.smpp.smspremium.fr';*/
  /*url = 'http://localhost:8080';*/

  constructor() { }

  headers() {
    const headerSettings: {[name: string]: string | string[]; } = {};
    headerSettings['Content-Type'] = 'application/json';
    headerSettings['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    // @ts-ignore
    headerSettings['id'] = localStorage.getItem('id');
    return new HttpHeaders(headerSettings);
  }
}
